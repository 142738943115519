import * as React from "react";
import AccordionWrapper from "../Accordion/AccordianWrapper";
import AccordionItem from "../Accordion/AccordianItem";

export default function Accordian({ accordianData }) {
  return (
    <AccordionWrapper>
      {accordianData.map((item, index) => (
        <AccordionItem
          key={item.id}
          index={index}
          id={`${index + 1} `}
          title={item.subTitle}
          description={item.subDescription}
        />
      ))}
    </AccordionWrapper>
  );
}
