import * as React from 'react';

export default function Accordionheader() {
    return (
        <div className="flex items-center justify-center bg-accordian_fuzz">
    
        <div className="max-w-sm lg:max-w-3xl p-10">
             <h1 className="leading-normal">Early years foundation stage has <span className="text-custom_pink-medium font-serif"> 7 areas of development </span> </h1>
        </div>
        
      </div>
    )
}