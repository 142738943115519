import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import NavBar from "../../components/navigation";

export default function Approchheader({ navigation, title, description }) {
  return (
    <section className="relative bg-approch-blob bg-cover md:bg-contain bg-no-repeat md:bg-right-top ">
      <NavBar navigation={navigation} textcolor={"text-gray-900"} />

      <div className=" py-16 ">
        <div className="xl:container px-4 sm:px-8 lg:px-16 xl:px-20 xl:mx-auto">
          <div className=" grid grid-cols-1 md:grid-cols-12 gap-6 xl:gap-28 items-center ">
            <div className="col-span-6  ">
              <p className="text-custom_pink-dark mb-2"> our approch </p>
              <h1 className="text-custom_blue-dark2 mb-6 lg:mb-8">{title}</h1>
              <p className="mt-4">{description}</p>
            </div>

            <div className=" col-span-6 overflow-visible lg:p-8 order-first md:order-last">
              <StaticImage
                src={"../../images/teacher_teaching_kids.svg"}
                className="w-full  mt-0 mr-0 mb-0 ml-0 pt-0 pr-0 pb-0 pl-0 object-contain object-top "
                alt="two little girls"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
