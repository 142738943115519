import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

export default function ApprochFeature({ framework, subframework }) {
  const color = [
    {
      colorName: "bg-yellow-200",
    },
    {
      colorName: "bg-blue-400",
    },
    {
      colorName: "bg-red-300",
    },
    {
      colorName: "bg-pink-400",
    },
  ];

  const frameworkList = subframework.map((item, index) => {
    return (
      <div
        key={item.id}
        className="flex flex-col md:flex-row items-center justify-center lg:items-start lg:justify-start "
      >
        <div
          className={`p-8 w-24 h-24 mb-4  ${color[index].colorName} shadow-lg text-center flex  justify-center rounded-lg mr-4`}
        >
          <p className="flex items-center justify-center font-cursive text-white">
            {"0" + (index + 1)}
          </p>
        </div>

        <div className="text-center lg:text-left ">
          <h4 className="mb-4"> {item.subTitle} </h4>

          <p>{item.subDescription}</p>
        </div>
      </div>
    );
  });

  return (
    <main className="container mx-auto mt-10">
      <div className="flex flex-col items-center justify-center p-2 sm:p-10">
        <h2 className="mb-4 items-center justify-center">{framework.title}</h2>
        <p className="max-w-2xl text-center">{framework.description}</p>

        <StaticImage
          className="md:-mt-28 max-w-6xl"
          src={"../../images/open_book.svg"}
        />
      </div>

      <div className="xl:container mx-auto overflow-hidden  p-2 sm:p-10">
        <div className="grid gap-4 md:gap-6 lg:gap-14 lg:grid-cols-2 ">
          {frameworkList}
        </div>
      </div>
    </main>
  );
}
