import React, { createContext, useState } from "react";

export const AccordionContext = createContext({
  active: 0,
  setActive: () => {},
});

const AccordionWrapper = (props) => {
  const [active, setActive] = useState(0);

  return (
    <AccordionContext.Provider
      value={{
        active,
        setActive,
      }}
    >
      <section className=" bg-no-repeat py-4 sm:p-10">
        <div className="container mx-auto mt-20">
          <form>{props.children}</form>
        </div>
      </section>
    </AccordionContext.Provider>
  );
};

export default AccordionWrapper;
