import React, { useContext } from "react";
import { AccordionContext } from "./AccordianWrapper";

const AccordionItem = (props) => {
  let indexPlus;

  const indexCount = (index) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const { active, setActive } = useContext(AccordionContext);

  const eventHandler = (e, index) => {
    e.preventDefault();
    setActive(index);
  };

  return (
    <div className="accordion-item   ">
      <h2 className="accordion-title   text-sm sm:text-xl md:text-2xl  relative mt-2 mb-4 ">
        <button
          onClick={(e) => eventHandler(e, props.index)}
          className={
            active === props.index
              ? "active flex items-center w-full text-custom_blue-dark2  "
              : "inactive flex items-center w-full"
          }
          aria-expanded={active === props.index ? "true" : "false"}
          aria-controls={"sect-" + indexCount(props.index)}
          aria-disabled={active === props.index ? "true" : "false"}
        >
          <span
            className={
              active === props.index
                ? "ml-2 mr-2 sm:mr-4 md:mr-10 font-cursive  text-sm sm:text-xl md:text-2xl text-custom_blue-dark2    "
                : "ml-2 mr-2 sm:mr-4 md:mr-10 font-cursive text-sm sm:text-xl md:text-2xl  "
            }
          >
            {" "}
            {props.id}.{" "}
          </span>
          <span className="title-wrapper inline-block">{props.title}</span>
          <span className="icon-wrapper">
            <span className={active === props.index ? "plus" : "minus"}></span>
          </span>
        </button>
      </h2>

      <div className="accordion-panel">
        <div
          id={"sect-" + indexCount(props.index)}
          className={
            active === props.index
              ? "panel-open h-auto w-full overflow-hidden"
              : "panel-close h-0 overflow-hidden w-full"
          }
        >
          <p className="text-sm md:text-xl text-gray-900 mb-4 leading-relaxed ml-6 sm:ml-10 md:ml-20 p-2">
            {" "}
            {props.description}{" "}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
